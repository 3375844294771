import { graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaArtstation,
  FaInstagram,
} from 'react-icons/fa';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto;
  /* margin-top: 5rem; */
  height: 20rem;

  .user-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    gap: 3rem;
  }

  .social-link {
    font-size: 2em;
    color: white;
  }

  .social-link:hover {
    color: lightgrey;
  }

  .footer-quote {
    margin: 20px auto;
    padding: 20px 20px;
    display: block;

    text-align: center;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1px;
    /* background: var(--primary-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  .notice-container {
    margin: 20px auto;
    padding-bottom: 20px;
    display: block;
    text-align: center;
    /* font-size: 1.4rem; */
    font-weight: 700;
    letter-spacing: 1px;
  }

  .social-link:hover {
    filter: drop-shadow(0 0 5px #ffffff33);
  }

  .twitter:hover {
    color: #1da1f2;
  }
  .instagram:hover {
    color: #e1306c;
  }
  .artstation:hover {
    color: #13aff0;
  }
  .linkedin:hover {
    color: #0a66c2;
  }
  .github:hover {
    color: #f0f6fc;
  }
`;

export default function Footer() {
  const { quotes } = useStaticQuery(graphql`
    query {
      quotes: allSanityQuote {
        nodes {
          quote
          author
        }
      }
    }
  `);
  const random = Math.floor(Math.random() * quotes.nodes.length);
  const randomQuote = quotes.nodes[random].quote;
  return (
    <FooterStyles>
      <div className="user-links">
        <OutboundLink
          aria-label="Twitter"
          className="social-link twitter"
          href="https://www.twitter.com/prismexpress"
        >
          <FaTwitter style={{ stroke: 'url(#blue-gradient)' }} />
        </OutboundLink>
        <OutboundLink
          aria-label="Instagram"
          className="social-link instagram"
          href="https://www.instagram.com/r5d4/"
        >
          <FaInstagram />
        </OutboundLink>
        <OutboundLink
          aria-label="ArtStation"
          className="social-link artstation"
          href="https://www.artstation.com/nickmbrown"
        >
          <FaArtstation />
        </OutboundLink>
        <OutboundLink
          aria-label="LinkedIn"
          className="social-link linkedin"
          href="https://www.linkedin.com/in/nick-brown-772058a3/"
        >
          <FaLinkedinIn />
        </OutboundLink>
        <OutboundLink
          aria-label="Github"
          className="social-link github"
          href="https://github.com/nickmbrown"
        >
          <FaGithub />
        </OutboundLink>
      </div>
      <span
        className="footer-quote"
        id="footer-quote"
      >
        <span>"{randomQuote}"</span><br/><br/>
        <span>- {quotes.nodes[random].author}</span>
      </span>

      <span className="notice-container center">
        &copy; Nick Brown {new Date().getFullYear()}
      </span>
    </FooterStyles>
  );
}
